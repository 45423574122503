@import "../../static/styles/base/base";
.keynote-section {
  // display: flex;
  justify-content: space-between;
  padding-top: 30px;
  .keynote-left,
  .keynote-right {
    display: flex;
    flex-direction: column;
  }
  .keynote-left {
    .keynote-name {
      font-family: 'Jura', 'Roboto', sans-serif;
      margin: 0;
      padding-bottom: 10px;
      font-size: 30px;
    }
    .keynote-subtitle {
      display: flex;
      flex-direction: row;
      font-weight: 300;
      letter-spacing: 2px;
      .keynote-company,
      .keynote-handle,
      .keynote-div {
        display: flex;
        color: $dm-highlight;
        margin: 0 20px;
        margin-left: 0px;
      }
    }
    .keynote-talk {
      .keynote-topic {
        padding: 10px 0px;
        color: $text-highlight;
        font-family: 'Jura', 'Roboto', sans-serif;
        font-size: 24px;
      }
    }
  }
  .keynote-bio-subtitle {
    font-weight: 300;
    margin-bottom: 20px;
  }
  .keynote-image {
    max-width: 350px;
    border-radius: 50%;
  }
}