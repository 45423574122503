@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
@import url(https://fonts.googleapis.com/css?family=Forum|Jura:300,400,500,700|Montserrat:300,400,500,700|Roboto:100,300,500,700,900);
@import url(https://fonts.googleapis.com/css2?family=Rock+Salt&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

body.light-mode {
  background-color: #e3e3e3;
  color: #333;
  transition: background-color 0.3s ease; }
  body.light-mode img.con-logo-flip-flip {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.light-mode .dark-mode-logo {
    display: none !important; }

body.dark-mode {
  background-color: #25282a;
  color: #dfdfdf; }
  body.dark-mode .navbar,
  body.dark-mode .nav-small {
    background-color: #1b2938; }
  body.dark-mode .dark-mode-toggle > button {
    color: #999; }
    body.dark-mode .dark-mode-toggle > button:last-child {
      color: lightblue; }
  body.dark-mode div.page-box {
    background-color: #25282a; }
  body.dark-mode div.secondary-nav {
    background-color: #1a1a1a; }
  body.dark-mode div.nav-menu .nav-small .nav-links {
    background-color: #10171d; }
  body.dark-mode div.nav-menu .nav-small div svg {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode div.highlight-boxes {
    background-color: #10171d !important; }
  body.dark-mode div.footer-bar {
    background-color: #10171d !important; }
  body.dark-mode div.footer-bar > div {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode div.home-page > div.right > img {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode img.navbar-logo,
  body.dark-mode img.con-logo-flip,
  body.dark-mode img.logo-reverse {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode span.focus {
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode button {
    color: #dfdfdf !important; }
  body.dark-mode div.date-box {
    background-color: #10171d !important; }
  body.dark-mode p.about-sub-text {
    color: #dfdfdf !important; }
  body.dark-mode .in-touch-icons {
    fill: #ddd; }
  body.dark-mode div.faq-list :not(.question) {
    /* Temporary until CSS is broken down */
    -webkit-filter: invert(100%);
            filter: invert(100%); }
  body.dark-mode p.tab-heading,
  body.dark-mode a.oc-twitter {
    color: #fff; }
  body.dark-mode img.spons-white-img,
  body.dark-mode img.logo-brighten {
    -webkit-filter: invert(100%) brightness(2);
            filter: invert(100%) brightness(2); }
  body.dark-mode .light-mode-logo {
    display: none !important; }

.content {
  margin-top: 60px; }
  .content h1 {
    font-size: 2rem; }
  .content img {
    width: 100%; }
  .content code {
    background-color: #cbe9ff;
    border-radius: 2px;
    font-size: 1em;
    padding: 0 5px; }
  .content a {
    text-decoration: none;
    color: #006688; }
  .content button {
    color: #006688;
    background-color: transparent;
    border: none;
    font-size: 1em;
    padding: 0; }

body.dark-mode .content code {
  background-color: #1b2938; }

body.dark-mode .content a {
  color: #ee09; }

body.dark-mode .content button {
  color: #ee09; }

body.dark-mode .glasses {
  border-color: #ddd; }

body.dark-mode .glasses:before {
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-color: #ddd; }

body.dark-mode .icon:after {
  /* FF3.6-15 */
  /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(135deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
  /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  border-color: #ddd; }

body.dark-mode .tabs [id^="tab"]:checked + label:after {
  border-bottom-color: #1b2938; }

.dark-mode-toggle {
  display: -webkit-flex;
  display: flex;
  margin: 4px auto; }
  .dark-mode-toggle > button {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease; }
    .dark-mode-toggle > button:last-child {
      color: #666; }
    .dark-mode-toggle > button:focus {
      outline: none; }
  .dark-mode-toggle .glasses:before {
    background-color: #ff78f9bf; }
  .dark-mode-toggle .icon:after {
    background-color: #ff78f9bf; }

.page-box {
  background-color: white;
  min-height: calc(100vh - 50px); }

@media (min-width: 200px) {
  .keynote-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row; } }

@media (max-width: 779px) {
  .keynote-section {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; } }

.footer-bar {
  /*background-color: #4A4A6E;*/
  background-color: #e3e3e3;
  color: #333; }
  .footer-bar .nav-link {
    color: #333;
    border: none;
    box-shadow: none;
    text-transform: inherit;
    padding: 2px;
    line-height: 1.6; }
  .footer-bar .nav__menu-item {
    border: none; }
  .footer-bar .nav-link:hover {
    cursor: pointer;
    color: #fff;
    background-color: inherit; }
  .footer-bar .footer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-align-items: center;
            align-items: center; }
  .footer-bar .footer-options {
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: flex-start;
            align-items: flex-start;
    height: 100%;
    /* min-width: 300px; */
    -webkit-flex-direction: column;
            flex-direction: column; }
    .footer-bar .footer-options .footer-sponsors {
      font-family: 'Jura', 'Montserrat', sans-serif; }
  .footer-bar .footer-link {
    padding: 20px;
    font-size: 12px;
    font-family: sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    color: #aaa;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    box-shadow: inset 0 0 1px rgba(255, 255, 255, 0.2); }
  .footer-bar .footer-link:hover {
    cursor: pointer;
    /*background-color: $nav-bkg-hover;*/ }
  .footer-bar .footer-social-icons:hover {
    cursor: pointer;
    /*fill: $nav-bkg-hover;*/ }
  .footer-bar .footer-brand {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    padding-top: 5px; }
    .footer-bar .footer-brand .footer-logo img {
      opacity: 0.75; }
  .footer-bar .footer-social {
    margin: 12px 0px; }
    .footer-bar .footer-social .footer-social-icons {
      width: 20px;
      fill: #333;
      margin-right: 41px; }
  .footer-bar .copy {
    font-size: 12px;
    font-family: sans-serif;
    padding: 2px;
    line-height: 1.6; }

.icon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
  font-size: 10px; }

.glasses {
  width: 2em;
  height: 2em;
  border-top: 3px solid #333;
  border-bottom: solid 0px transparent !important;
  border-radius: 50% 50% 0 0; }

.glasses:before {
  content: "";
  position: absolute;
  top: -0.4em;
  left: -3.2em;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid #333;
  border-radius: 50% 50% 50% 50%; }

.glasses:after {
  content: "";
  position: absolute;
  top: -0.4em;
  left: 1.7em;
  width: 3.5em;
  height: 3.5em;
  border: 3px solid #333;
  border-radius: 50% 50% 50% 50%; }

input[type='checkbox'].icon {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  vertical-align: 2px;
  outline: none; }
  input[type='checkbox'].icon:checked + label {
    left: 30px; }
  input[type='checkbox'].icon:focus-visible {
    outline: solid 2px white; }

_:-ms-lang(x),
.glasses {
  border: solid 1px black !important;
  border-radius: 0% !important;
  font-size: 10px; }

_:-ms-lang(x),
.dark-mode-toggle:after {
  content: 'Shades';
  font-size: 14px; }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.navbar {
  position: fixed;
  top: 0;
  right: 100;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  padding: 25px;
  height: 60px;
  background-color: #f3f3f3;
  z-index: 7; }
  .navbar .toggle-group {
    position: absolute;
    width: 120px;
    right: 0; }

.navbar-logo {
  margin-top: -15px;
  margin-left: -4px;
  position: fixed; }

.nav-dates {
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: 400;
  font-family: "Jura", "Montserrat", sans-serif; }

.secondary-nav {
  height: 30px;
  display: -webkit-flex;
  display: flex;
  position: fixed;
  width: 100%;
  background-color: #e3e3e3;
  margin-top: 60px;
  z-index: 6; }

.nav-links {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  text-transform: uppercase;
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  z-index: 6; }

.nav-menu {
  padding-top: 5px;
  z-index: 6; }
  .nav-menu div {
    z-index: 6; }
  .nav-menu .nav-small {
    display: block;
    position: absolute;
    top: 0px;
    width: 100%;
    left: 0px;
    height: 60px;
    padding-top: 15px;
    padding-left: 12px;
    z-index: 99;
    text-align: left;
    line-height: 40px; }
  .nav-menu .nav-small .nav-links {
    display: block;
    margin-left: -12px;
    margin-top: -3px;
    text-align: center;
    background-color: #e3e3e3; }
  .nav-menu .nav-small a {
    display: block;
    position: relative;
    box-sizing: content-box;
    zoom: 1.5;
    margin: 5px; }

@media only screen and (min-width: 992px) {
  div.nav-small {
    display: none !important; } }

@media only screen and (max-width: 992px) {
  .navbar {
    margin-left: 50px;
    width: 80%;
    background-color: transparent !important; }
    .navbar .container {
      padding: 0px;
      margin: 0px;
      width: 60%;
      left: 0; }
    .navbar .toggle-group {
      padding: 0px;
      margin: 0px;
      width: 30%; }
  .secondary-nav {
    height: 60px;
    margin: 0px; }
  .backdrop {
    padding: 5px !important; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.about-sub-text {
  color: #000;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0px; }
  .about-sub-text a {
    color: #409abf;
    text-decoration: underline; }

.clear {
  display: none !important; }

#mc_embed_signup .email {
  border: solid 2px #409ABF !important;
  border-radius: 5px !important;
  float: left;
  font-size: 16px;
  height: 40px;
  z-index: 1 !important;
  margin-right: 10px;
  width: 300px;
  -webkit-user-select: unset !important;
     -moz-user-select: unset !important;
      -ms-user-select: unset !important;
          user-select: unset !important; }

#mc_embed_signup .button {
  display: block;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  padding: 8px;
  margin-bottom: 20px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all .5s;
  border: solid 0px;
  color: #fff;
  background: linear-gradient(to left, skyblue 25%, aliceblue 75%);
  background-size: 400% 100%;
  background-position: right bottom;
  font-weight: bold;
  z-index: 3;
  text-align: center;
  cursor: pointer !important; }

#mc_embed_signup .button:hover {
  color: #409abf;
  background-position: left bottom; }

#mc_embed_signup .button:active {
  margin-top: 5px;
  margin-left: 5px; }

.in-touch-icons {
  width: 100px;
  fill: #333; }
  .in-touch-icons:first-child {
    margin-right: 40px; }

.faq-list {
  padding-bottom: 40px; }
  .faq-list .faq-single .faq-question {
    display: -webkit-flex;
    display: flex;
    padding: 0px 10px;
    font-size: 16px;
    font-weight: 300;
    -webkit-align-items: center;
            align-items: center; }
    .faq-list .faq-single .faq-question .question {
      font-weight: 500; }
  .faq-list .faq-single .faq-answer {
    display: -webkit-flex;
    display: flex;
    font-size: 16px;
    font-weight: 300;
    -webkit-align-items: center;
            align-items: center; }
  .faq-list .faq-single .faq-q,
  .faq-list .faq-single .faq-a {
    font-size: 30px;
    font-weight: 500;
    padding-right: 10px;
    max-width: 28px; }
  .faq-list .faq-single .question,
  .faq-list .faq-single .answer {
    padding-left: 10px;
    min-height: 30px;
    border-left: 3px solid #409abf;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex; }
    .faq-list .faq-single .question ul,
    .faq-list .faq-single .answer ul {
      padding-left: 20px; }
  .faq-list .faq-single .question {
    display: -webkit-flex;
    display: flex; }

.rc-collapse > .rc-collapse-item > .rc-collapse-header {
  height: 65px !important;
  line-height: unset !important;
  text-indent: 0px !important;
  color: #666 !important;
  display: -webkit-flex;
  display: flex;
  border-bottom: 2px solid #eee; }

.rc-collapse {
  border: none !important;
  width: 100% !important; }

.rc-collapse-content {
  padding: 0 52px !important; }

i.arrow {
  margin-left: 15px;
  -webkit-align-self: center;
          align-self: center; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409ABF;
  display: block !important; }

.reciprocity {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  width: 100%; }
  .reciprocity .wording,
  .reciprocity .logo-space {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .reciprocity .wording {
    width: inherit;
    padding-right: 30px; }
  .reciprocity .logo-space {
    height: inherit;
    padding-left: 10px;
    border-left: 1px solid #409ABF; }
    .reciprocity .logo-space a img {
      width: inherit;
      padding-bottom: 30px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat; }

@media (min-width: 200px) {
  .logo-space {
    width: 100px; }
    .logo-space a img {
      max-width: 100px; } }

@media (min-width: 576px) {
  .logo-space {
    width: 200px; }
    .logo-space a img {
      max-width: 200px; } }

.oc-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  /*justify-content: space-between;*/
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.oc-member {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  margin-bottom: 30px; }

.oc-member {
  width: 265px;
  margin-right: 30px; }
  .oc-member .oc-name {
    font-size: 20px;
    font-weight: 400; }
  .oc-member .oc-title {
    color: #ffade6;
    text-transform: uppercase; }
  .oc-member .oc-title,
  .oc-member .oc-twitter {
    font-size: 12px; }
  .oc-member .oc-twitter {
    color: black; }

.organizers .tab-title {
  font-weight: 500;
  padding: 0px 0px 10px 0px; }

.oc-member-box {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row; }
  .oc-member-box .oc-member-img {
    margin-right: 10px;
    min-width: 95px;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }


@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.keynote-section {
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 30px; }
  .keynote-section .keynote-left,
  .keynote-section .keynote-right {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .keynote-section .keynote-left .keynote-name {
    font-family: 'Jura', 'Roboto', sans-serif;
    margin: 0;
    padding-bottom: 10px;
    font-size: 30px; }
  .keynote-section .keynote-left .keynote-subtitle {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    font-weight: 300;
    letter-spacing: 2px; }
    .keynote-section .keynote-left .keynote-subtitle .keynote-company,
    .keynote-section .keynote-left .keynote-subtitle .keynote-handle,
    .keynote-section .keynote-left .keynote-subtitle .keynote-div {
      display: -webkit-flex;
      display: flex;
      color: #6195ed;
      margin: 0 20px;
      margin-left: 0px; }
  .keynote-section .keynote-left .keynote-talk .keynote-topic {
    padding: 10px 0px;
    color: #ffade6;
    font-family: 'Jura', 'Roboto', sans-serif;
    font-size: 24px; }
  .keynote-section .keynote-bio-subtitle {
    font-weight: 300;
    margin-bottom: 20px; }
  .keynote-section .keynote-image {
    max-width: 350px;
    border-radius: 50%; }

.con-page .reg-button {
  display: block;
  font-size: 16px;
  font-weight: medium;
  text-transform: uppercase;
  font-family: Montserrat, sans-serif;
  padding: 8px;
  margin-bottom: 20px;
  width: 90%;
  max-width: 300px;
  height: 40px;
  border-radius: 5px;
  box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
  transition: all .5s;
  border: solid 0px;
  color: #fff;
  background: linear-gradient(to left, skyblue 25%, aliceblue 75%);
  background-size: 400% 100%;
  background-position: right bottom;
  font-weight: bold;
  z-index: 3;
  text-align: center; }

.con-page .reg-button:hover {
  color: #409abf;
  background-position: left bottom; }

.con-page .reg-button:active {
  margin-top: 5px;
  margin-left: 5px; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
.dates, .container-view-master, .inner, .bridge, .rim, .shadow, .frame, .oval, .paper, .handle, .handle-shadow, .handle-stem {
  position: absolute; }

.dates {
  background: #FEF0D7; }

.wrapper {
  margin: 60px; }

.container-view-master {
  margin: auto;
  top: 50px;
  bottom: 0;
  left: 0;
  right: 0;
  width: 420px;
  height: 200px; }

.oval {
  width: 420px;
  height: 100px;
  background: #FEF0D7;
  border-radius: 100px / 50px;
  z-index: 3;
  margin-top: -50px; }

.paper {
  width: 400px;
  height: 250px;
  background: white;
  border-radius: 50%;
  z-index: 5;
  top: -20px;
  left: 70px; }

.frame {
  z-index: 6;
  padding: 0px 60px;
  width: 420px;
  height: 380px;
  margin: 20px 0;
  background: red;
  border-radius: 50% / 10%;
  color: white;
  text-align: center;
  text-indent: .1em; }
  .frame:before {
    content: '';
    position: absolute;
    z-index: 6;
    top: 9%;
    bottom: 10%;
    right: -5%;
    left: -5%;
    background: inherit;
    border-radius: 5% / 50%; }

.handle {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  background-color: black;
  z-index: 5;
  right: -100px; }
  .handle .handle-shadow {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: 4px solid #777;
    left: 6px;
    top: 6px;
    z-index: 5; }
  .handle .handle-stem {
    width: 40px;
    height: 40px;
    background: transparent;
    border-radius: 0 0 100% 0;
    -moz-border-radius: 0 0 100% 0;
    -webkit-border-radius: 0 0 100% 0;
    border-right: 30px solid black;
    border-bottom: 30px solid black;
    top: 40px;
    right: 20px;
    z-index: 3; }

.inner {
  width: 150px;
  height: 150px;
  background: #C5DFEA;
  border-radius: 100%;
  border: 10px solid #931a1a;
  z-index: 7; }
  .inner:before, .inner:after {
    position: absolute;
    content: ''; }
  .inner:before {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: #FFF;
    opacity: 0.7;
    top: 20px;
    left: 60px; }
  .inner:after {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background: #FFF;
    opacity: 0.7;
    top: 30px;
    left: 30px; }

.inner.right {
  left: 250px;
  z-index: 7; }

.shadow {
  top: 35px;
  left: 13px;
  width: 150px;
  height: 75px;
  border-bottom: 35px solid rgba(0, 0, 0, 0.2);
  border-radius: 0 0 100px 100px;
  -webkit-transform: rotate(-40deg);
          transform: rotate(-40deg); }

.bridge {
  z-index: 6;
  width: 330px;
  height: 100px;
  left: 50px;
  border-radius: 100%;
  border-top: 60px solid #c54949; }
  .bridge:before {
    position: absolute;
    content: '';
    width: 50px;
    height: 50px;
    background: #c54949;
    border: 5px solid #931a1a;
    border-radius: 100%;
    top: -72px;
    left: 130px; }

.rim {
  z-index: -1;
  width: 70px;
  height: 130px;
  background: #931a1a;
  border-radius: 0 100px 100px 100px;
  left: 90px;
  top: -25px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg); }

.rim.right {
  left: 260px;
  border-radius: 100px 0 100px 100px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg); }

@media (max-width: 800px) {
  .container-view-master, .frame {
    -webkit-transform: scale(0.7);
            transform: scale(0.7); } }

.dates-list {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  margin-left: -20px; }
  .dates-list .date-box {
    font-weight: 300;
    padding: 10px;
    font-size: 14px;
    background-color: #f3f3f3;
    width: 220px;
    margin: 20px;
    border-left: 6px solid #409abf;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    transition-property: color,background-color,box-shadow,-webkit-transform;
    transition-property: color,background-color,box-shadow,transform;
    transition-property: color,background-color,box-shadow,transform,-webkit-transform;
    transition-duration: .15s; }
    .dates-list .date-box .dl-title {
      font-weight: 500;
      font-size: 16px;
      line-height: 20px; }
    .dates-list .date-box .dl-description {
      margin-top: 3px; }
    .dates-list .date-box .dl-date {
      margin-top: 5px;
      color: #409abf; }

.real-bullet-list {
  margin-left: 50px; }

.no-bullets {
  list-style: none;
  list-style-position: inside;
  text-indent: -23px; }

.fake-checkbox {
  margin-right: 10px; }

.footnotes {
  margin-top: 10px;
  margin-left: 15px;
  font-size: 12px; }

.closed-text {
  color: red;
  text-shadow: 1px 1px 5px #000;
  font-size: 30px; }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

body.dark-mode div.highlight-boxes {
  background-color: #10171d; }

@media only screen and (min-width: 780px) {
  body.dark-mode .twitter-dark {
    display: block !important;
    background-color: #10171d; } }

body.dark-mode .twitter-light {
  display: none; }

body.dark-mode .reg-button {
  color: #fff !important;
  background: linear-gradient(to left, #409abf 25%, #10171d 75%) !important;
  background-size: 400% 100% !important;
  background-position: right bottom !important; }

body.dark-mode .reg-button:hover {
  color: #fff !important;
  background-position: left bottom !important; }

body.dark-mode div.font-punk {
  color: #ff00a2;
  text-shadow: 2px 2px #eaf63c; }

body.dark-mode div.sizzle-text-section {
  background-color: rgba(0, 0, 0, 0.5); }

body.dark-mode div.sizzle-header {
  background-color: rgba(0, 0, 0, 0.5); }

body.dark-mode div.sizzle-text-title {
  color: #009eff; }

body.dark-mode .sizzle-bullet-item .sizzle-icon {
  -webkit-filter: invert(100%);
          filter: invert(100%); }

body.dark-mode .keynote-speaker-name {
  font-size: 28px;
  font-family: "Rock Salt", cursive;
  color: #eaf63c !important;
  text-shadow: 2px 2px #f31bb2 !important;
  font-weight: 900; }

.home-page {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  width: 100%; }
  .home-page div.font-punk {
    font-size: 50px;
    font-family: "Rock Salt", cursive;
    text-align: center;
    color: #009eff;
    text-shadow: 2px 2px #ff00a2;
    margin: 10px 20px; }
  .home-page img.punk-img {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    max-width: 100px; }
  .home-page div.sizzle {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    min-height: 100px;
    margin: 40px;
    border-left: 1px solid #31bac3;
    border-right: 1px solid #31bac3;
    box-shadow: 0px -15px 15px 0px cyan; }
  .home-page div.sizzle-image {
    margin: 2px; }
  .home-page div.sizzle-header {
    background-color: rgba(0, 0, 0, 0.1);
    height: 198px; }
  .home-page div.sizzle-text-title {
    font-size: 30px;
    text-align: center;
    font-family: "Rock Salt", cursive;
    color: #f31bb2; }
  .home-page div.sizzle-text-section {
    padding: 10px 20px;
    margin-top: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    box-shadow: 0px 15px 15px 0px cyan; }
  .home-page .sizzle-bullet-item {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    padding: 15px 0px 0px; }
    .home-page .sizzle-bullet-item .sizzle-icon {
      min-width: 100px;
      max-width: 100px;
      display: -webkit-flex;
      display: flex; }
      .home-page .sizzle-bullet-item .sizzle-icon img {
        -webkit-justify-content: center;
                justify-content: center;
        -webkit-align-items: center;
                align-items: center;
        max-width: 100%;
        height: 100%; }
    .home-page .sizzle-bullet-item .sizzle-bullet {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-left: 20px; }
      .home-page .sizzle-bullet-item .sizzle-bullet .sizzle-bullet-title {
        font-weight: 900; }
      .home-page .sizzle-bullet-item .sizzle-bullet .sizzle-bullet-desc {
        font-size: 12px; }
  .home-page .twitter-light {
    background-color: aliceblue; }
  .home-page .centered-top {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center; }
  .home-page .col {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-flex-basis: 100%;
            flex-basis: 100%;
    /*flex: 1;*/ }
  .home-page .order-button {
    margin: 30px; }
  .home-page .reg-button {
    display: block;
    font-size: 16px;
    font-weight: medium;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    padding: 8px;
    margin-bottom: 20px;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    transition: all 0.5s;
    border: solid 0px;
    color: #fff;
    background: linear-gradient(to left, skyblue 25%, aliceblue 75%);
    background-size: 400% 100%;
    background-position: right bottom;
    font-weight: bold;
    z-index: 3;
    text-align: center; }
  .home-page .reg-button:hover {
    color: #409abf;
    background-position: left bottom; }
  .home-page .reg-button:active {
    margin-top: 5px;
    margin-left: 5px; }
  .home-page .left {
    -webkit-flex: 1 1;
            flex: 1 1; }
    .home-page .left img {
      width: 100%; }
    .home-page .left .img-responsive {
      width: 80%; }
    .home-page .left .twitter-zone {
      width: 90%;
      border-radius: 5px;
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
      transition-property: color, background-color, box-shadow, -webkit-transform;
      transition-property: color, background-color, box-shadow, transform;
      transition-property: color, background-color, box-shadow, transform, -webkit-transform;
      transition-duration: 0.15s; }
    .home-page .left .twitter-dark {
      display: none; }
    .home-page .left p.timeline-Tweet-text {
      -webkit-margin-after: 0px !important;
              margin-block-end: 0px !important;
      white-space: normal !important;
      font-size: 12px; }
  .home-page .keynote {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px; }
    .home-page .keynote .keynote-header .keynote-title {
      font-size: 16px;
      text-transform: uppercase;
      font-family: "Jura", "Montserrat", sans-serif;
      font-weight: 700; }
    .home-page .keynote .keynote-header .keynote-speaker .keynote-speaker-name {
      font-size: 28px;
      font-family: "Rock Salt", cursive;
      color: #f31bb2;
      text-shadow: 2px 2px #eaf63c;
      font-weight: 900; }
    .home-page .keynote .keynote-header .keynote-speaker .keynote-speaker-info .keynote-image {
      max-width: 200px;
      max-height: 200px;
      border-radius: 50%;
      margin-left: 10px;
      float: right; }
    .home-page .keynote .keynote-header .keynote-speaker .keynote-speaker-info .keynote-para {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
      font-size: 14px;
      margin: 10px 0px;
      text-align: justify; }
  @media only screen and (max-width: 779px) {
    .home-page .col {
      display: inline-block; }
      .home-page .col .reg-button {
        width: 100%;
        background: none !important;
        background-color: #409abf !important;
        color: #fff !important; }
    .home-page .twitter-zone {
      display: none; }
    .home-page .left .sizzle-icon {
      min-width: 0px;
      display: none; }
    .home-page .right .highlight-box {
      margin: 0px !important; } }
  .home-page .right {
    padding: 15px;
    -webkit-flex: 1 1;
            flex: 1 1; }
    .home-page .right .reg-button {
      width: 100% !important;
      visibility: hidden; }
    .home-page .right img {
      width: 100% !important; }
    .home-page .right .photo-credit {
      font-size: 12px;
      color: #999;
      margin: 0px; }
    .home-page .right .highlight-box {
      margin-top: 20px;
      font-size: 2.5em;
      line-height: 1.2;
      margin-left: 20px; }
      .home-page .right .highlight-box .vision-highlight {
        font-size: 30px;
        font-family: "Jura";
        margin: 20px 0px; }
      .home-page .right .highlight-box .num-highlight {
        font-family: Lato, Gotham, Arial;
        font-weight: 900;
        margin-right: 20px; }
      .home-page .right .highlight-box .highlight-spans {
        text-transform: uppercase;
        font-weight: 100; }
      .home-page .right .highlight-box .focus {
        color: transparent;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5); }
      .home-page .right .highlight-box .highlight-boxes {
        background-color: aliceblue;
        padding: 20px 20px 20px 50px;
        border-radius: 5px;
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
        transition-property: color, background-color, box-shadow, -webkit-transform;
        transition-property: color, background-color, box-shadow, transform;
        transition-property: color, background-color, box-shadow, transform, -webkit-transform;
        transition-duration: 0.15s; }

_:-ms-lang(x),
.focus {
  color: rgba(0, 0, 0, 0.5) !important; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.sponsors div {
  margin-bottom: 30px; }

.spons-page {
  margin-top: 30px; }
  .spons-page .spons-groups {
    text-align: center;
    padding-bottom: 50px !important; }
    .spons-page .spons-groups .spons-block {
      padding-top: 30px;
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .spons-page .spons-groups .spons-block .spons-title {
        font-family: 'Montserrat', sans-serif;
        margin-bottom: 30px;
        font-weight: 900;
        text-transform: uppercase; }
      .spons-page .spons-groups .spons-block h2 {
        font-size: calc(4vh + 4vw); }
      .spons-page .spons-groups .spons-block h3 {
        font-size: calc(3.5vh + 3.5vw); }
      .spons-page .spons-groups .spons-block h4 {
        font-size: calc(2.5vh + 2.5vw); }
      .spons-page .spons-groups .spons-block h5 {
        font-size: calc(2vh + 2vw); }
      .spons-page .spons-groups .spons-block h6 {
        font-size: calc(1.75vh + 1.75vw); }
      .spons-page .spons-groups .spons-block .two-line {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-align-items: center;
                align-items: center;
        -webkit-justify-content: space-evenly;
                justify-content: space-evenly;
        padding: 0vw 5vw; }
      .spons-page .spons-groups .spons-block .spons-img {
        max-width: 100%;
        max-height: 100%;
        margin-bottom: 50px; }
      .spons-page .spons-groups .spons-block .plat-spons {
        max-width: calc(28vh + 28vw); }
      .spons-page .spons-groups .spons-block .gold-spons {
        max-width: calc(25vh + 25vw); }
      .spons-page .spons-groups .spons-block .half-gold-spons {
        max-width: calc(10vh + 10vw); }
      .spons-page .spons-groups .spons-block .silver-spons {
        max-width: calc(22vh + 22vw); }
      .spons-page .spons-groups .spons-block .other-spons {
        max-width: calc(18vh + 18vw);
        max-height: calc(15vh + 5vw); }
      .spons-page .spons-groups .spons-block .spons-text-block {
        font-size: calc(1vh + 1vw); }
        .spons-page .spons-groups .spons-block .spons-text-block .party-text {
          font-family: 'Jura', 'Montserrat', sans-serif;
          font-size: calc(1.2vh + 1.2vw);
          color: #ffade6;
          font-weight: 500; }
      .spons-page .spons-groups .spons-block .foodbev-spons {
        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: row;
                flex-direction: row;
        -webkit-justify-content: space-around;
                justify-content: space-around;
        -webkit-flex-wrap: wrap;
                flex-wrap: wrap; }
        .spons-page .spons-groups .spons-block .foodbev-spons .single-foodbev-spons {
          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
                  flex-direction: column;
          width: 50%; }
          .spons-page .spons-groups .spons-block .foodbev-spons .single-foodbev-spons .fdbev-spons {
            max-width: calc(15vh + 15vw);
            margin-bottom: 20px; }
      .spons-page .spons-groups .spons-block .toool-logo {
        max-height: 210px; }

.gold {
  color: goldenrod; }

.silver {
  color: silver; }

.bronze {
  color: #c9992b; }

.ctf-spons {
  color: #6195ed; }

.all-dark {
  -webkit-filter: brightness(0);
          filter: brightness(0); }

.all-bright {
  -webkit-filter: invert(1) grayscale(1) brightness(1000);
          filter: invert(1) grayscale(1) brightness(1000); }

.sub-party-spons {
  max-width: calc(8vh + 8vw) !important;
  max-height: calc(7vh + 2vw) !important;
  margin: 20px; }

.sub-party-logos {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.gp-logo {
  margin-bottom: 0px !important; }

.map-container {
  margin-top: 20px;
  overflow: hidden;
  height: 240px; }

.text-area {
  padding-bottom: 30px; }

.students-list {
  -webkit-margin-start: 3em;
          margin-inline-start: 3em;
  -webkit-margin-after: 1em;
          margin-block-end: 1em; }


.training .training-card {
  padding-top: 100px;
  margin-top: -80px; }
  .training .training-card .training-title {
    background-color: skyblue;
    color: #25282a;
    font-size: 20px;
    padding: 5px 10px;
    text-transform: uppercase;
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center; }
    .training .training-card .training-title span {
      width: inherit !important; }
  .training .training-card .training-notes {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
    -webkit-justify-content: space-between;
            justify-content: space-between; }
    .training .training-card .training-notes .training-notes-left .training-presenter,
    .training .training-card .training-notes .training-notes-left .training-format {
      display: -webkit-flex;
      display: flex; }
      .training .training-card .training-notes .training-notes-left .training-presenter .training-heading,
      .training .training-card .training-notes .training-notes-left .training-format .training-heading {
        min-width: 120px; }
    .training .training-card .training-notes .training-notes-right {
      margin-top: 30px; }
  .training .training-card .training-desc,
  .training .training-card .training-prereqs,
  .training .training-card .training-equipment {
    margin-bottom: 10px; }
    .training .training-card .training-desc ul,
    .training .training-card .training-prereqs ul,
    .training .training-card .training-equipment ul {
      list-style-position: outside;
      padding-left: 20px; }
  .training .training-card .training-desc {
    margin: 10px 0px; }
  .training .training-card .training-heading {
    font-weight: 700; }

.training-text p {
  margin-bottom: 20px; }

@media only screen and (max-width: 992px) {
  .training-notes-right {
    margin: 15px 0px; } }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.75);
  padding: 50px;
  z-index: 100; }

.modal-container {
  background-color: #fff;
  max-width: 560px;
  min-height: 300px;
  margin: 0 auto;
  padding: 0px;
  overflow: hidden; }
  .modal-container .modal-header {
    border-top: 6px solid #6195ed;
    border-bottom: 1px solid #e3e3e3;
    min-height: 40px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    -webkit-align-items: center;
            align-items: center;
    background-color: #fff;
    padding: 0px 20px;
    position: relative; }
  .modal-container .modal-content {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center; }
  .modal-container .modal-description-heading {
    font-size: 16px; }
  .modal-container .modal-description {
    font-size: 14px; }
  .modal-container .modal-title {
    color: #111;
    font-family: Montserrat, sans-serif;
    font-size: 18px; }
  .modal-container .modal-close {
    color: #6195ed;
    font-size: 30px;
    margin-top: -5px; }
    .modal-container .modal-close:hover {
      cursor: pointer;
      color: #111; }
  .modal-container .modal-headline {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: space-between;
            justify-content: space-between;
    border-bottom: 1px solid rgba(227, 227, 227, 0.5);
    padding-bottom: 10px;
    margin-bottom: 10px; }
    .modal-container .modal-headline .modal-headine-percentage {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: row;
              flex-direction: row; }
    .modal-container .modal-headline .tech-label {
      margin-left: 0px;
      padding-left: 5px; }
  .modal-container .modal-time,
  .modal-container .modal-authors {
    font-family: "Rock Salt", cursive;
    color: #6195ed; }

.volunteer-modal {
  height: auto; }

body.dark-mode .modal-container,
body.dark-mode .modal-header,
body.dark-mode .modal-title {
  background-color: #25282a !important;
  color: #e3e3e3 !important; }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.donutchart-track {
  fill: transparent;
  stroke: #DAE2E5;
  stroke-width: 26; }

.donutchart-indicator {
  fill: transparent;
  stroke: #6195ed;
  stroke-width: 26;
  stroke-dasharray: 0 10000;
  transition: stroke-dasharray .3s ease; }

.donutchart {
  margin: 0 auto;
  border-radius: 50%;
  display: block;
  margin-left: -25px; }

.donutchart-text {
  font-family: 'Roboto';
  fill: #607580; }

.donutchart-text-val {
  font-size: 22px; }

.donutchart-text-percent {
  font-size: 14px; }

.donutchart-text-label {
  font-size: 9px; }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.schedule {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }

.schedule-heading {
  font-size: 24px;
  font-family: "Rock Salt", cursive;
  text-align: center;
  margin: 10px 20px; }

body.dark-mode .schedule-heading {
  color: #eaf63c !important;
  text-shadow: 2px 2px #f31bb2 !important; }

.tz-note {
  font-size: 12px; }

.schedule-talks {
  font-family: Lato, Gotham, Arial;
  /*************************************/
  /************ Tab Headers ************/
  /*************************************/
  /*************************************/
  /************ Talks Grid *************/
  /*************************************/ }
  .schedule-talks .tabs {
    display: -webkit-flex;
    display: flex;
    -webkit-justify-content: center;
            justify-content: center; }
  .schedule-talks .tabs li {
    float: left;
    width: 40%;
    display: block; }
  .schedule-talks .tabs input[type="radio"] {
    display: none; }
  .schedule-talks .tabs label {
    display: block;
    padding: 25px 0;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    background: #d9d9d6;
    color: #707372;
    cursor: pointer;
    position: relative;
    transition: all 0.2s ease-in-out; }
  .schedule-talks .tabs label:hover {
    background: #eee;
    color: #f31bb2; }
  .schedule-talks .tabs label span {
    display: block;
    font-size: 6em;
    line-height: 1;
    font-weight: 900; }
  .schedule-talks .tabs .tab-content {
    z-index: 2;
    display: none;
    overflow: hidden;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    padding: 25px;
    position: absolute;
    top: 184px;
    left: 0;
    background: #ffffff; }
  .schedule-talks .tabs [id^="tab"]:checked + label {
    background: #f31bb2;
    color: #ffffff; }
  .schedule-talks .tabs [id^="tab"]:checked + label:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 20px);
    border: 20px solid transparent;
    border-bottom-color: #fff; }
  .schedule-talks .tabs [id^="tab"]:checked ~ [id^="tab-content"] {
    display: block; }
  .schedule-talks table {
    width: 100%; }
  .schedule-talks tr:nth-of-type(even) {
    background-color: #ffffff; }
  .schedule-talks tr:first-of-type {
    padding-top: 50px; }
  .schedule-talks td {
    border: 0;
    width: 75%;
    vertical-align: top;
    padding: 15px; }
  .schedule-talks td:nth-of-type(2n+1) {
    width: 25%;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase; }
  .schedule-talks .grid-wrapper {
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    color: #444;
    padding-bottom: 30px;
    font-size: 14px; }
    .schedule-talks .grid-wrapper a {
      text-decoration: none; }
      .schedule-talks .grid-wrapper a:hover {
        cursor: pointer; }
  .schedule-talks .track {
    -webkit-justify-content: center;
            justify-content: center;
    -webkit-align-items: center;
            align-items: center;
    display: -webkit-flex;
    display: flex;
    text-transform: uppercase;
    font-family: "Rock Salt", cursive;
    font-size: calc(.8vw + .8vh);
    color: #fff;
    background: #6195ed; }
  .schedule-talks .box {
    background-color: #eee;
    color: #333;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: solid 8px #f31bb2;
    padding: 5px 10px;
    display: -webkit-flex;
    display: flex;
    height: 100%;
    overflow: hidden;
    box-shadow: 0px 1px 2px 0px #c7c7c7; }
    .schedule-talks .box .talk-time {
      color: #f31bb2;
      display: -webkit-flex;
      display: flex;
      width: 35px !important;
      min-width: 35px;
      margin-right: 10px;
      margin-bottom: 10px; }
    .schedule-talks .box .truncate-overflow-1 {
      --max-lines: 1;
      word-break: break-all; }
    .schedule-talks .box .truncate-overflow-4 {
      --max-lines: 4; }
    .schedule-talks .box .truncate-overflow {
      position: relative;
      max-height: calc(1.4rem * var(--max-lines));
      max-height: calc(var(--lh) * var(--max-lines));
      overflow: hidden;
      padding-right: 1rem; }
    .schedule-talks .box .truncate-overflow::before {
      position: absolute;
      content: "...";
      inset-block-end: 0;
      inset-inline-end: 2px;
      margin-left: 10px; }
    .schedule-talks .box .truncate-overflow::after {
      content: "";
      position: absolute;
      inset-inline-end: 0;
      width: 1rem;
      height: calc(1rem * (var(--max-lines) + 1));
      background: #eee; }

html {
  --lh: 1.4rem;
  line-height: 1.4rem;
  line-height: var(--lh); }

@font-face {
  font-family: Cyber;
  src: url("https://assets.codepen.io/605876/Blender-Pro-Bold.otf");
  font-display: swap; }

.cybr-btn {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 1%)
	);
  --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%);
  --primary-hue: 210;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --font-size: 26px;
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary-hue: 60;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
		0 2%,
		100% 2%,
		100% 95%,
		95% 95%,
		95% 90%,
		85% 90%,
		85% 95%,
		8% 95%,
		0 70%
	);
  --clip-two: polygon(
		0 78%,
		100% 78%,
		100% 100%,
		95% 100%,
		95% 90%,
		85% 90%,
		85% 100%,
		8% 100%,
		0 78%
	);
  --clip-three: polygon(
		0 44%,
		100% 44%,
		100% 54%,
		95% 54%,
		95% 54%,
		85% 54%,
		85% 54%,
		8% 54%,
		0 54%
	);
  --clip-four: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-five: polygon(
		0 0,
		100% 0,
		100% 0,
		95% 0,
		95% 0,
		85% 0,
		85% 0,
		8% 0,
		0 0
	);
  --clip-six: polygon(
		0 40%,
		100% 40%,
		100% 85%,
		95% 85%,
		95% 85%,
		85% 85%,
		85% 85%,
		8% 85%,
		0 70%
	);
  --clip-seven: polygon(
		0 63%,
		100% 63%,
		100% 80%,
		95% 80%,
		95% 80%,
		85% 80%,
		85% 80%,
		8% 80%,
		0 70%
	);
  font-family: "Cyber","Rock Salt", cursive;
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: var(--font-size);
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  font-weight: 700;
  border: 0;
  min-width: 250.84px;
  height: 62.38px;
  /* line-height: 75px; */
  margin: 20px 0px;
  transition: background 0.2s;
  -webkit-border-radius: 3px;
  padding: 0.4em 2em;
  border-radius: 3px;
  text-decoration: none;
  z-index: 1; }

.cybr-btn:hover {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.8%)
	); }

.cybr-btn:active {
  --primary: hsl(
		var(--primary-hue),
		85%,
		calc(var(--primary-lightness, 50) * 0.6%)
	); }

.cybr-btn:after,
.cybr-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  z-index: -1; }

.cybr-btn:before {
  background: var(--shadow-primary);
  -webkit-transform: translate(var(--border), 0);
          transform: translate(var(--border), 0); }

.cybr-btn:after {
  background: #007bff;
  background: var(--primary); }

.cybr-btn__tag {
  position: absolute;
  padding: 1px 4px;
  letter-spacing: 1px;
  line-height: 1;
  bottom: -5%;
  right: 5%;
  font-weight: normal;
  color: black;
  font-size: var(--label-size); }

.cybr-btn__glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  background: var(--shadow-primary);
  text-shadow: 2px 2px var(--shadow-primary), -2px -2px var(--shadow-secondary);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  -webkit-animation: glitch 2s infinite;
          animation: glitch 2s infinite;
  display: none; }

.cybr-btn:hover .cybr-btn__glitch {
  display: block; }

.cybr-btn__glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  -webkit-clip-path: var(--clip);
          clip-path: var(--clip);
  background: #007bff;
  background: var(--primary);
  z-index: -1; }

@-webkit-keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

@keyframes glitch {
  0% {
    -webkit-clip-path: var(--clip-one);
            clip-path: var(--clip-one); }
  2%,
  8% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  6% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  9% {
    -webkit-clip-path: var(--clip-two);
            clip-path: var(--clip-two);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  10% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  13% {
    -webkit-clip-path: var(--clip-three);
            clip-path: var(--clip-three);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  14%,
  21% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  25% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  30% {
    -webkit-clip-path: var(--clip-five);
            clip-path: var(--clip-five);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%), 0);
            transform: translate(calc(var(--shimmy-distance) * -1%), 0); }
  35%,
  45% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * -1%));
            transform: translate(calc(var(--shimmy-distance) * -1%)); }
  40% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%));
            transform: translate(calc(var(--shimmy-distance) * 1%)); }
  50% {
    -webkit-clip-path: var(--clip-six);
            clip-path: var(--clip-six);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  55% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(calc(var(--shimmy-distance) * 1%), 0);
            transform: translate(calc(var(--shimmy-distance) * 1%), 0); }
  60% {
    -webkit-clip-path: var(--clip-seven);
            clip-path: var(--clip-seven);
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  31%,
  61%,
  100% {
    -webkit-clip-path: var(--clip-four);
            clip-path: var(--clip-four); } }

.cybr-btn:nth-of-type(2) {
  --primary-hue: 260; }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
:root {
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; }

html {
  margin: 0;
  padding: 0; }

*, *:before, *:after {
  box-sizing: border-box;
  font-size: 100%;
  margin: 0;
  padding: 0; }

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0); }

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 576px) {
  .container {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container {
    max-width: 960px; } }

@media (min-width: 1200px) {
  .container {
    max-width: 1140px; } }

.container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto; }

@media (min-width: 200px) {
  .page-box {
    padding: 60px 0px; } }

@media (min-width: 576px) {
  .container-sm {
    max-width: 540px; } }

@media (min-width: 768px) {
  .container-md {
    max-width: 720px; } }

@media (min-width: 992px) {
  .container-lg {
    max-width: 960px; }
  .page-box {
    padding: 120px 0px; } }

@media (min-width: 1200px) {
  .container-xl {
    max-width: 1140px; } }

/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* 
    base/colors is standard colors
    names chosen from http://chir.ag/projects/name-that-color/  
*/
/* whites and blacks */
/* greys - lightest to darkest */
/* blues - main highlights */
/* pinks */
/* purps */
/* yellows */
/* punk theme one - blacks, blues, and pinks */
/* light mode */
/* dark mode */
.rwt__tabs[data-rwt-vertical="true"] {
  display: -webkit-flex;
  display: flex; }

.rwt__tablist:not([aria-orientation="vertical"]) {
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px; }

.rwt__tablist[aria-orientation="vertical"] {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  -webkit-flex-grow: 0;
          flex-grow: 0;
  border-right: 1px solid #ddd;
  margin-right: 25px;
  /*height: 400px;*/ }

.rwt__tab {
  background: transparent;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  padding: 10px 20px 10px 0px;
  transition: background 0.3s cubic-bezier(0.22, 0.61, 0.36, 1);
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  text-align: end;
  width: 160px; }

.rwt__tab[aria-selected="false"]:hover,
.rwt__tab:focus {
  outline: 0;
  background-color: #f4f4f4;
  background-color: rgba(0, 0, 0, 0.05);
  cursor: pointer; }

.rwt__tab[aria-selected="true"] {
  position: relative; }

.rwt__tab[aria-selected="true"]:after {
  content: '';
  position: absolute; }

.rwt__tablist:not([aria-orientation="vertical"]) .rwt__tab[aria-selected="true"]:after {
  bottom: -1px;
  left: 0;
  width: 100%;
  border-bottom: 3px solid skyblue; }

.rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
  right: -1px;
  top: 0;
  height: 100%;
  border-right: 6px solid skyblue; }

.rwt__tabpanel {
  min-height: 580px;
  overflow-y: visible; }

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409abf; }

.rwt__tabs[data-rwt-vertical="true"] span {
  width: 100% !important; }

/*****************
     MOBILE
******************/
.rwt__tabs[data-rwt-vertical="false"] {
  display: block; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] {
    display: block;
    border-right: none;
    padding-bottom: 10px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tablist[aria-orientation="vertical"] .rwt__tab[aria-selected="true"]:after {
    right: -1px;
    top: 0;
    height: 100%;
    border-right: none; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tab {
    padding: 10px;
    width: inherit;
    border: 1px solid #eee;
    font-size: 16px;
    font-weight: 100;
    border-top-right-radius: 5px;
    border-top-left-radius: 5px; }
  .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel {
    height: inherit; }
    .rwt__tabs[data-rwt-vertical="false"] .rwt__tabpanel .tab-title {
      display: none; }

.tab-paragraph {
  margin-bottom: 15px; }

/* FONT STACK */
/* FONT WEIGHTS */
/* BODY TEXT */
/* HEADING TEXT */
/* LINE HEIGHTS */
body,
input,
textarea,
option,
select {
  color: #282828;
  font-weight: 400;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  line-height: 1.666;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  text-rendering: optimizeLegibility !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important; }

h1,
h2,
h3 {
  margin: 0;
  font-family: 'Jura', 'Montserrat', sans-serif; }

p,
input,
textarea,
select,
option {
  font-size: inherit; }

i,
.i,
em {
  font-style: italic; }

b,
.b,
strong {
  font-weight: 700; }

a {
  text-decoration: none;
  color: inherit;
  font-size: inherit; }

h1 {
  font-size: 2.96074em; }

h2 {
  font-size: 2.22111em; }

h3 {
  font-size: 1.66625em; }

h4 {
  font-size: 1.25em; }

h1,
h2,
h3,
h4 {
  line-height: 1.5; }

@media all and (min-width: 960px) {
  body {
    font-size: 16px; } }

@media all and (max-width: 959px) and (min-width: 600px) {
  body {
    font-size: 14px; } }

@media all and (max-width: 599px) {
  body {
    font-size: 13px; } }

.text-highlight {
  color: #409abf; }

body.dark-mode .text-highlight {
  color: #ffade6 !important; }

.speakers .title {
  margin-top: 0px; }

.speaker-section {
  margin-top: 30px;
  padding-bottom: 50px; }

.keynote-section {
  display: -webkit-flex;
  display: flex; }
  .keynote-section .kernelcon-text-area {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column; }
  .keynote-section .bio-name {
    margin: 30px 0px 0px 0px; }
  .keynote-section .bio-title {
    -webkit-margin-before: 10px;
            margin-block-start: 10px;
    font-weight: 100;
    letter-spacing: 2px; }
    .keynote-section .bio-title a {
      color: #ffade6;
      text-decoration: none; }
      .keynote-section .bio-title a:not(:first-child):before {
        content: "\2022";
        color: #ffade6;
        margin: 0px 8px; }
  .keynote-section .bio-text {
    font-weight: 300;
    font-size: 16px;
    line-height: 28px; }
  .keynote-section .keynote-image-area {
    padding: 40px 10px 10px 20px; }

.speaker-section .title {
  padding-bottom: 30px; }

.speaker-section .title-reversed {
  padding: 20px 0px; }

.speaker-section .speakers {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between; }
  .speaker-section .speakers .individual-speaker-section {
    padding-bottom: 30px;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: row;
            flex-direction: row;
    width: 400px; }
    .speaker-section .speakers .individual-speaker-section .bio-image {
      border-radius: 50%; }
    .speaker-section .speakers .individual-speaker-section h3 {
      font-size: 1.25em;
      margin-top: 0px;
      margin-bottom: 10px; }
    .speaker-section .speakers .individual-speaker-section .speaker-image {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column;
      padding-right: 15px; }
    .speaker-section .speakers .individual-speaker-section .speaker-wording {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
              flex-direction: column; }
      .speaker-section .speakers .individual-speaker-section .speaker-wording .speaker-name {
        text-decoration: none;
        font-size: 20px;
        font-family: "Rock Salt", cursive;
        /*color: black;*/ }
      .speaker-section .speakers .individual-speaker-section .speaker-wording .speaker-titles {
        color: #6195ed;
        /*margin-bottom: 10px;*/ }
        .speaker-section .speakers .individual-speaker-section .speaker-wording .speaker-titles span:not(:first-child):before {
          content: "\2022";
          color: #ffade6;
          margin: 0px 8px; }
      .speaker-section .speakers .individual-speaker-section .speaker-wording .bio-image {
        float: right;
        margin-top: -30px;
        padding: 0px 5px 5px 5px;
        width: 130px;
        height: 130px; }
      .speaker-section .speakers .individual-speaker-section .speaker-wording p {
        -webkit-margin-before: 0.5em;
                margin-block-start: 0.5em;
        font-weight: 300;
        font-size: 15px;
        line-height: 20px; }

.callout-area .callout-title {
  font-size: 20px;
  text-transform: uppercase;
  padding: 5px 10px;
  margin-bottom: 0px;
  background-color: skyblue;
  color: #25282a; }

.callout-area .callout-section {
  padding: 15px 0px 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap; }
  .callout-area .callout-section > * {
    -webkit-flex: 1 1 300px;
            flex: 1 1 300px; }
  .callout-area .callout-section .callout-name-logo {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    max-width: 240px;
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
    margin-right: 40px; }
    .callout-area .callout-section .callout-name-logo .callout-author {
      font-size: 16px;
      font-weight: 300;
      margin-bottom: 20px;
      margin-left: 10px; }
    .callout-area .callout-section .callout-name-logo .callout-logo {
      display: -webkit-flex;
      display: flex;
      -webkit-align-items: center;
              align-items: center;
      -webkit-justify-content: center;
              justify-content: center; }
      .callout-area .callout-section .callout-name-logo .callout-logo .callout-img {
        min-width: 130px;
        max-height: 120px;
        margin-bottom: 30px; }
  .callout-area .callout-section .callout-description {
    font-size: 14px;
    line-height: 1.75;
    margin-bottom: 20px; }
    .callout-area .callout-section .callout-description p {
      margin-bottom: 14px; }

