@import '../../../static/styles/base/base';
.schedule {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.schedule-heading {
  font-size: 24px;
  font-family: $font-punk;
  text-align: center;
  margin: 10px 20px;
}

body.dark-mode {
  .schedule-heading {
    color: #eaf63c !important;
    text-shadow: 2px 2px $dm-punk-highlight !important;
  }
}

.tz-note {
  font-size: 12px;
}

.schedule-talks {
  font-family: Lato, Gotham, Arial;
  /*************************************/
  /************ Tab Headers ************/
  /*************************************/
  .tabs {
    display: flex;
    justify-content: center;
  }
  .tabs li {
    float: left;
    width: 40%;
    display: block;
  }
  .tabs input[type="radio"] {
    display: none;
  }
  .tabs label {
    display: block;
    padding: 25px 0;
    font-weight: normal;
    text-transform: uppercase;
    text-align: center;
    background: #d9d9d6;
    color: #707372;
    cursor: pointer;
    position: relative;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .tabs label:hover {
    background: #eee;
    color: $dm-punk-highlight;
  }
  .tabs label span {
    display: block;
    font-size: 6em;
    line-height: 1;
    font-weight: 900;
  }
  .tabs .tab-content {
    z-index: 2;
    display: none;
    overflow: hidden;
    width: 100%;
    font-size: 17px;
    line-height: 25px;
    padding: 25px;
    position: absolute;
    top: 184px;
    left: 0;
    background: #ffffff;
  }
  .tabs [id^="tab"]:checked+label {
    background: $dm-punk-highlight;
    color: #ffffff;
  }
  .tabs [id^="tab"]:checked+label:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: calc(50% - 20px);
    border: 20px solid transparent;
    border-bottom-color: #fff;
  }
  .tabs [id^="tab"]:checked~[id^="tab-content"] {
    display: block;
  }
  table {
    width: 100%;
  }
  tr:nth-of-type(even) {
    background-color: #ffffff;
  }
  tr:first-of-type {
    padding-top: 50px;
  }
  td {
    border: 0;
    width: 75%;
    vertical-align: top;
    padding: 15px;
  }
  td:nth-of-type(2n+1) {
    width: 25%;
    text-align: right;
    font-weight: bold;
    text-transform: uppercase;
  }
  /*************************************/
  /************ Talks Grid *************/
  /*************************************/
  .grid-wrapper {
    margin-top: 20px;
    display: grid;
    grid-gap: 10px;
    color: #444;
    padding-bottom: 30px;
    font-size: 14px;
    a {
      text-decoration: none;
      &:hover {
        cursor: pointer;
      }
    }
  }
  .track {
    justify-content: center;
    align-items: center;
    display: flex;
    text-transform: uppercase;
    font-family: $font-punk;
    font-size: calc(.8vw + .8vh);
    color: #fff;
    background: $dm-highlight;
  }
  .box {
    background-color: #eee;
    color: #333;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border-left: solid 8px $dm-punk-highlight;
    padding: 5px 10px;
    display: flex;
    height: 100%;
    overflow: hidden;
    -webkit-box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 1);
    -moz-box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 1);
    box-shadow: 0px 1px 2px 0px rgba(199, 199, 199, 1);
    .talk-time {
      color: $dm-punk-highlight;
      display: flex;
      width: 35px !important;
      min-width: 35px;
      margin-right: 10px;
      margin-bottom: 10px;
    }
    .truncate-overflow-1 {
      --max-lines: 1;
      word-break: break-all;
    }
    .truncate-overflow-4 {
      --max-lines: 4;
    }
    .truncate-overflow {
      position: relative;
      max-height: calc(var(--lh) * var(--max-lines));
      overflow: hidden;
      padding-right: 1rem;
    }
    .truncate-overflow::before {
      position: absolute;
      content: "...";
      inset-block-end: 0;
      inset-inline-end: 2px;
      margin-left: 10px;
    }
    .truncate-overflow::after {
      content: "";
      position: absolute;
      inset-inline-end: 0;
      width: 1rem;
      height: calc(1rem * (var(--max-lines) + 1));
      background: #eee;
    }
  }
}

html {
  --lh: 1.4rem;
  line-height: var(--lh);
}