
.map-container {
  margin-top: 20px;
  overflow: hidden;
  height: 240px;
}

.text-area {
  padding-bottom: 30px;
}
