.real-bullet-list {
  margin-left: 50px;
}

.no-bullets {
  list-style: none;
  list-style-position: inside;
  text-indent: -23px;
}

.fake-checkbox {
  margin-right: 10px;
}

.footnotes {
  margin-top: 10px;
  margin-left: 15px;
  font-size: 12px;
}

.closed-text {
  color: red;
  text-shadow: 1px 1px 5px #000;
  font-size: 30px;
}