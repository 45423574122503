.icon {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    display: block;
    font-size: 10px;
}

.glasses {
    width: 2em;
    height: 2em;
    border-top: 3px solid #333;
    border-bottom: solid 0px transparent !important;
    border-radius: 50% 50% 0 0;
}

.glasses:before {
    content: "";
    position: absolute;
    top: -0.4em;
    left: -3.2em;
    width: 3.5em;
    height: 3.5em;
    border: 3px solid #333;
    border-radius: 50% 50% 50% 50%;
}

.glasses:after {
    content: "";
    position: absolute;
    top: -0.4em;
    left: 1.7em;
    width: 3.5em;
    height: 3.5em;
    border: 3px solid #333;
    border-radius: 50% 50% 50% 50%;
}

input[type='checkbox'].icon {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    cursor: pointer;
    vertical-align: 2px;
    outline: none;
    &:checked+label {
        left: 30px;
    }
    &:focus-visible {
        outline: solid 2px white;
    }
}

//edge does not support :before and :after on a checkbox element
_:-ms-lang(x),
.glasses {
    border: solid 1px black !important;
    border-radius: 0% !important;
    font-size: 10px;
}

_:-ms-lang(x),
.dark-mode-toggle:after {
    content: 'Shades';
    font-size: 14px;
}