.training {
  .training-card {
    padding-top: 100px; // hack for anchor scroll
    margin-top: -80px; // hack for anchor scroll
    .training-title {
      background-color: skyblue;
      color: #25282a;
      font-size: 20px;
      padding: 5px 10px;
      text-transform: uppercase;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: inherit !important;
      }
    }
    .training-notes {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .training-notes-left {
        .training-presenter,
        .training-format {
          display: flex;
          .training-heading {
            min-width: 120px;
          }
        }
      }
      .training-notes-right {
        margin-top: 30px;
      }
    }
    .training-desc,
    .training-prereqs,
    .training-equipment {
      ul {
        list-style-position: outside;
        padding-left: 20px;
      }
      margin-bottom: 10px;
    }
    .training-desc {
      margin: 10px 0px;
    }
    .training-heading {
      font-weight: 700;
    }
  }
}

.training-text {
  p {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 992px) {
  .training-notes-right {
    margin: 15px 0px;
  }
}