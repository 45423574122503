@import "static/styles/base/base";
body.light-mode {
  background-color: #e3e3e3;
  color: #333;
  transition: background-color 0.3s ease;
  img.con-logo-flip-flip {
    filter: invert(100%);
  }
  .dark-mode-logo {
    display: none !important;
  }
}

body.dark-mode {
  background-color: $dm-main-color;
  color: #dfdfdf;
  .navbar,
  .nav-small {
    //background-color: rgba(27, 42, 59, .8)
    background-color: #1b2938;
    // background-color: $dm-punk-darklight;
  }
  .dark-mode-toggle>button {
    color: #999;
    &:last-child {
      color: lightblue;
    }
  }
  div.page-box {
    background-color: $dm-main-color;
  }
  div.secondary-nav {
    //background-color: rgba(12, 20, 26, .8)
    // background-color: rgb(16, 23, 29);
    background-color: $dm-darker-main;
  }
  div.nav-menu {
    //        background-color: rgb(16, 23, 29);
    .nav-small .nav-links {
      background-color: rgb(16, 23, 29);
    }
    .nav-small div svg {
      filter: invert(100%);
    }
  }
  div.highlight-boxes {
    background-color: rgb(16, 23, 29) !important;
  }
  div.footer-bar {
    background-color: rgb(16, 23, 29) !important;
  }
  div.footer-bar>div {
    filter: invert(100%);
  }
  div.home-page>div.right>img {
    filter: invert(100%);
  }
  img.navbar-logo,
  img.con-logo-flip,
  img.logo-reverse {
    filter: invert(100%);
  }
  span.focus {
    filter: invert(100%);
  }
  button {
    color: #dfdfdf !important;
  }
  div.date-box {
    background-color: rgb(16, 23, 29) !important;
  }
  p.about-sub-text {
    color: #dfdfdf !important;
  }
  .in-touch-icons {
    fill: #ddd;
  }
  div.faq-list :not(.question) {
    /* Temporary until CSS is broken down */
    filter: invert(100%);
  }
  p.tab-heading,
  a.oc-twitter {
    color: #fff;
  }
  img.spons-white-img,
  img.logo-brighten {
    filter: invert(100%) brightness(2);
  }
  .light-mode-logo {
    display: none !important;
  }
}

.content {
  margin-top: 60px;
  h1 {
    font-size: 2rem;
  }
  img {
    width: 100%;
  }
  code {
    background-color: #cbe9ff;
    border-radius: 2px;
    font-size: 1em;
    padding: 0 5px;
  }
  a {
    text-decoration: none;
    color: #006688;
  }
  button {
    color: #006688;
    background-color: transparent;
    border: none;
    font-size: 1em;
    padding: 0;
  }
}

body.dark-mode {
  .content code {
    background-color: #1b2938;
  }
  .content a {
    color: #ee09;
  }
  .content button {
    color: #ee09;
  }
  .glasses {
    border-color: #ddd;
  }
  .glasses:before {
    background: -moz-linear-gradient( -45deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( -45deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( 135deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-color: #ddd;
  }
  .icon:after {
    background: -moz-linear-gradient( -45deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* FF3.6-15 */
    background: -webkit-linear-gradient( -45deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient( 135deg, rgba(255, 255, 255, 0.65) 0%, #ff00f4bf);
    /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    border-color: #ddd;
  }
  .tabs [id^="tab"]:checked+label:after {
    border-bottom-color: #1b2938;
  }
}

.dark-mode-toggle {
  display: flex;
  margin: 4px auto;
  &>button {
    font-size: 1.2em;
    background: none;
    border: none;
    color: #ffe600;
    cursor: pointer;
    transition: color 0.3s ease;
    &:last-child {
      color: #666;
    }
    &:focus {
      outline: none;
    }
  }
  .glasses:before {
    background-color: #ff78f9bf;
  }
  .icon:after {
    background-color: #ff78f9bf;
  }
}

.page-box {
  background-color: white;
  min-height: calc(100vh - 50px);
}

@media (min-width: 200px) {
  .keynote-section {
    display: flex;
    flex-direction: row;
  }
}

@media (min-width: 992px) {}

@media (max-width: 779px) {
  .keynote-section {
    display: flex;
    flex-direction: column;
  }
}