@import "../../static/styles/base/base";
body.dark-mode {
  div.highlight-boxes {
    background-color: rgb(16, 23, 29);
  }
  @media only screen and (min-width: 780px) {
    .twitter-dark {
      display: block !important;
      background-color: rgb(16, 23, 29);
    }
  }
  .twitter-light {
    display: none;
  }
  .reg-button {
    color: #fff !important;
    background: linear-gradient( to left, rgb(64, 154, 191) 25%, rgb(16, 23, 29) 75%) !important;
    background-size: 400% 100% !important;
    background-position: right bottom !important;
  }
  .reg-button:hover {
    color: #fff !important;
    background-position: left bottom !important;
  }
  div.font-punk {
    color: $hollywood-cerise;
    text-shadow: 2px 2px $starship;
  }
  div.sizzle-text-section {
    background-color: rgba(0, 0, 0, 0.5);
  }
  div.sizzle-header {
    background-color: rgba(0, 0, 0, 0.5);
  }
  div.sizzle-text-title {
    color: $azure-radiance;
  }
  .sizzle-bullet-item {
    .sizzle-icon {
      filter: invert(100%);
    }
  }
  .keynote-speaker-name {
    font-size: 28px;
    font-family: $font-punk;
    color: #eaf63c !important;
    text-shadow: 2px 2px $dm-punk-highlight !important;
    font-weight: 900;
  }
}

.home-page {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  div.font-punk {
    font-size: 50px;
    font-family: $font-punk;
    // color: #ff00a2;
    // text-shadow: 2px 2px #fffc76;
    text-align: center;
    color: $azure-radiance;
    text-shadow: 2px 2px $hollywood-cerise;
    margin: 10px 20px;
  }
  img.punk-img {
    display: flex;
    flex-direction: row;
    max-width: 100px;
  }
  div.sizzle {
    display: flex;
    flex-direction: column;
    min-height: 100px;
    margin: 40px;
    border-left: 1px solid $scooter;
    border-right: 1px solid $scooter;
    -moz-box-shadow: 0px -15px 15px 0px cyan;
    -webkit-box-shadow: 0px -15px 15px 0px cyan;
    box-shadow: 0px -15px 15px 0px cyan;
  }
  div.sizzle-image {
    margin: 2px;
  }
  div.sizzle-header {
    background-color: rgba(0, 0, 0, 0.1);
    height: 198px;
  }
  div.sizzle-text-title {
    // margin: 10px 20px;
    font-size: 30px;
    text-align: center;
    font-family: $font-punk;
    color: $dm-punk-highlight;
  }
  div.sizzle-text-section {
    padding: 10px 20px;
    margin-top: 3px;
    background-color: rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0px 15px 15px 0px cyan;
    -webkit-box-shadow: 0px 15px 15px 0px cyan;
    box-shadow: 0px 15px 15px 0px cyan;
  }
  .sizzle-bullet-item {
    display: flex;
    flex-direction: row;
    padding: 15px 0px 0px;
    .sizzle-icon {
      min-width: 100px;
      max-width: 100px;
      display: flex;
      img {
        justify-content: center;
        align-items: center;
        max-width: 100%;
        height: 100%;
      }
    }
    .sizzle-bullet {
      display: flex;
      flex-direction: column;
      padding-left: 20px;
      .sizzle-bullet-title {
        font-weight: 900;
      }
      .sizzle-bullet-desc {
        font-size: 12px;
        ;
      }
    }
  }
  .twitter-light {
    background-color: aliceblue;
  }
  .centered-top {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    width: 100%;
    justify-content: center;
  }
  .col {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    /*flex: 1;*/
  }
  .order-button {
    margin: 30px;
  }
  .reg-button {
    display: block;
    font-size: 16px;
    font-weight: medium;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    padding: 8px;
    margin-bottom: 20px;
    width: 90%;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    transition: all 0.5s;
    border: solid 0px;
    color: #fff;
    background: linear-gradient(to left, skyblue 25%, aliceblue 75%);
    background-size: 400% 100%;
    background-position: right bottom;
    font-weight: bold;
    z-index: 3;
    text-align: center;
  }
  .reg-button:hover {
    color: rgb(64, 154, 191);
    background-position: left bottom;
  }
  .reg-button:active {
    margin-top: 5px;
    margin-left: 5px;
  }
  .left {
    flex: 1;
    img {
      width: 100%;
      // margin-top: -180px;
      // margin-left: -45px;
      // margin-bottom: -60px;
    }
    .img-responsive {
      width: 80%;
    }
    .twitter-zone {
      width: 90%;
      border-radius: 5px;
      -webkit-box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
      box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
      -webkit-transition-property: color, background-color, -webkit-box-shadow, -webkit-transform;
      transition-property: color, background-color, -webkit-box-shadow, -webkit-transform;
      transition-property: color, background-color, box-shadow, transform;
      transition-property: color, background-color, box-shadow, transform, -webkit-box-shadow, -webkit-transform;
      -webkit-transition-duration: 0.15s;
      transition-duration: 0.15s;
    }
    .twitter-dark {
      display: none;
    }
    p.timeline-Tweet-text {
      margin-block-end: 0px !important;
      white-space: normal !important;
      font-size: 12px;
    }
  }
  .keynote {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
    .keynote-header {
      .keynote-title {
        font-size: 16px;
        text-transform: uppercase;
        font-family: "Jura", "Montserrat", sans-serif;
        font-weight: 700;
        // color: $white;
      }
      .keynote-speaker {
        .keynote-speaker-name {
          font-size: 28px;
          font-family: $font-punk;
          color: $dm-punk-highlight;
          text-shadow: 2px 2px #eaf63c;
          font-weight: 900;
        }
        .keynote-speaker-info {
          .keynote-image {
            max-width: 200px;
            max-height: 200px;
            border-radius: 50%;
            margin-left: 10px;
            float: right;
          }
          .keynote-para {
            font-family: $font-stack;
            font-size: 14px;
            margin: 10px 0px;
            text-align: justify;
          }
        }
      }
    }
  }
  @media only screen and (max-width: 779px) {
    .col {
      display: inline-block;
      .reg-button {
        width: 100%;
        background: none !important;
        background-color: rgb(64, 154, 191) !important;
        color: #fff !important;
      }
    }
    .twitter-zone {
      display: none;
    }
    .left {
      .sizzle-icon {
        min-width: 0px;
        display: none;
      }
    }
    .right {
      .highlight-box {
        margin: 0px !important;
      }
    }
  }
  .right {
    padding: 15px;
    .reg-button {
      width: 100% !important;
      visibility: hidden; // Remove this when registration goes live //
    }
    flex: 1;
    img {
      width: 100% !important;
    }
    .photo-credit {
      font-size: 12px;
      color: #999;
      margin: 0px;
    }
    .highlight-box {
      margin-top: 20px;
      font-size: 2.5em;
      line-height: 1.2;
      margin-left: 20px;
      .vision-highlight {
        font-size: 30px;
        font-family: "Jura";
        margin: 20px 0px;
      }
      .num-highlight {
        font-family: Lato, Gotham, Arial;
        font-weight: 900;
        margin-right: 20px;
      }
      .highlight-spans {
        text-transform: uppercase;
        font-weight: 100;
      }
      .focus {
        color: transparent;
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.5);
      }
      .highlight-boxes {
        background-color: aliceblue;
        padding: 20px 20px 20px 50px;
        border-radius: 5px;
        box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
        transition-property: color, background-color, box-shadow, transform;
        transition-duration: 0.15s;
      }
    }
  }
}

// this is a stupid fix to make blurry text show up on certain versions of microsoft edge because why would we ever want every browser to render things the same way thats crazy lol
_:-ms-lang(x),
.focus {
  color: rgba(0, 0, 0, 0.5) !important;
}