@import '../../static/styles/base/colors';
@import '../../static/styles/base/variables';
.about-sub-text {
  color: #000;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  padding: 10px 0px;
  a {
    color: $boston-blue;
    text-decoration: underline;
  }
}

.clear {
  display: none !important;
}

#mc_embed_signup {
  .email {
    border: solid 2px #409ABF !important;
    border-radius: 5px !important;
    float: left;
    font-size: 16px;
    height: 40px;
    z-index: 1 !important;
    margin-right: 10px;
    width: 300px;
    user-select: unset !important;
  }
  .button {
    display: block;
    font-size: 16px;
    font-weight: medium;
    text-transform: uppercase;
    font-family: Montserrat, sans-serif;
    padding: 8px;
    margin-bottom: 20px;
    height: 40px;
    border-radius: 5px;
    box-shadow: 0 13px 27px -5px rgba(50, 50, 93, 0.25), 0 8px 16px -8px rgba(0, 0, 0, 0.3), 0 -6px 16px -6px rgba(0, 0, 0, 0.025);
    transition: all .5s;
    border: solid 0px;
    color: #fff;
    background: linear-gradient(to left, skyblue 25%, aliceblue 75%);
    background-size: 400% 100%;
    background-position: right bottom;
    font-weight: bold;
    z-index: 3;
    text-align: center;
    cursor: pointer !important;
  }
  .button:hover {
    color: rgb(64, 154, 191);
    background-position: left bottom;
  }
  .button:active {
    margin-top: 5px;
    margin-left: 5px;
  }
}

.in-touch-icons {
  width: 100px;
  fill: #333;
  &:first-child {
    margin-right: 40px;
  }
}

.faq-list {
  padding-bottom: 40px;
  .faq-single {
    .faq-question {
      display: flex;
      padding: 0px 10px;
      font-size: 16px;
      font-weight: 300;
      align-items: center;
      .question {
        font-weight: 500;
      }
    }
    .faq-answer {
      display: flex;
      font-size: 16px;
      font-weight: 300;
      align-items: center;
    }
    .faq-q,
    .faq-a {
      font-size: 30px;
      font-weight: 500;
      padding-right: 10px;
      max-width: 28px;
    }
    .question,
    .answer {
      padding-left: 10px;
      min-height: 30px;
      border-left: 3px solid $boston-blue;
      align-items: center;
      display: flex;
      ul {
        padding-left: 20px;
      }
    }
    .question {
      display: flex;
    }
  }
}

.rc-collapse>.rc-collapse-item>.rc-collapse-header {
  height: 65px !important;
  line-height: unset !important;
  text-indent: 0px !important;
  color: #666 !important;
  display: flex;
  border-bottom: 2px solid #eee;
}

.rc-collapse {
  border: none !important;
  width: 100% !important;
}

.rc-collapse-content {
  padding: 0 52px !important;
}

i.arrow {
  margin-left: 15px;
  align-self: center;
}

.tab-title {
  font-family: 'Jura', 'Montserrat', sans-serif;
  font-size: 24px;
  font-weight: 500;
  color: #409ABF;
  display: block !important;
}

.reciprocity {
  display: flex;
  flex-direction: row;
  width: 100%;
  .wording,
  .logo-space {
    display: flex;
    flex-direction: column;
  }
  .wording {
    width: inherit;
    padding-right: 30px;
  }
  .logo-space {
    height: inherit;
    padding-left: 10px;
    border-left: 1px solid #409ABF;
    a {
      img {
        width: inherit;
        padding-bottom: 30px;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
    }
  }
}

@media (min-width: 200px) {
  .logo-space {
    width: 100px;
    a {
      img {
        max-width: 100px;
      }
    }
  }
}

@media (min-width: 576px) {
  .logo-space {
    width: 200px;
    a {
      img {
        max-width: 200px;
      }
    }
  }
}

.oc-list {
  display: flex;
  flex-direction: row;
  /*justify-content: space-between;*/
  flex-wrap: wrap;
}

.oc-member {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.oc-member {
  width: 265px;
  margin-right: 30px;
  .oc-name {
    font-size: 20px;
    font-weight: 400;
  }
  .oc-title {
    color: $text-highlight;
    text-transform: uppercase;
  }
  .oc-title,
  .oc-twitter {
    font-size: 12px;
  }
  .oc-twitter {
    color: black;
  }
}

.organizers .tab-title {
  font-weight: 500;
  padding: 0px 0px 10px 0px;
}

.oc-member-box {
  display: flex;
  flex-direction: row;
  .oc-member-img {
    margin-right: 10px;
    min-width: 95px;
    display: flex;
    justify-content: center;
  }
}